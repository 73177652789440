<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <CategoryForm
        :data="empty"
        @save="onSave"
        :save_text="$pgettext('form','Create')"
        :autocomplete_tag="(query) => autocompleteTags({query: query, category: TagCategory.FAQ})"
    />
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {SuccessNotification} from "@/domain/core/exception/exceptions";
import CategoryForm from "@/domain/faq/components/CategoryForm.vue";
import {TagCategory} from "@/domain/core/utils/constants";

export default {
  name: 'NewFaqCategory',
  computed: {
    TagCategory() {
      return TagCategory
    }
  },
  components: {CategoryForm},
  data() {
    return {
      empty: {
        name: {},
        is_active: false,
        order: 0
      }
    }
  },
  methods: {
    ...mapActions('faq', [
      'createCategory',
    ]),
    ...mapActions('core', [
      'autocompleteTags',
    ]),
    async onSave(data) {
      await this.createCategory(data)
      await this.$router.push({name: 'faqCategory.list'})
      throw new SuccessNotification(this.$pgettext('faq_category', 'Faq Category created!'))
    }
  }
}
</script>

<style scoped>

</style>